// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5-f-64-f-2346-dbb-00-ab-js": () => import("./../../../src/pages/5f64f2346dbb00ab.js" /* webpackChunkName: "component---src-pages-5-f-64-f-2346-dbb-00-ab-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-air-pollution-from-domestic-burning-js": () => import("./../../../src/pages/air-pollution-from-domestic-burning.js" /* webpackChunkName: "component---src-pages-air-pollution-from-domestic-burning-js" */),
  "component---src-pages-air-pollution-js": () => import("./../../../src/pages/air-pollution.js" /* webpackChunkName: "component---src-pages-air-pollution-js" */),
  "component---src-pages-be-the-change-js": () => import("./../../../src/pages/be-the-change.js" /* webpackChunkName: "component---src-pages-be-the-change-js" */),
  "component---src-pages-data-hub-diffusion-tubes-js": () => import("./../../../src/pages/data-hub/diffusion-tubes.js" /* webpackChunkName: "component---src-pages-data-hub-diffusion-tubes-js" */),
  "component---src-pages-data-hub-forecast-and-alerts-email-sign-up-js": () => import("./../../../src/pages/data-hub/forecast-and-alerts/email-sign-up.js" /* webpackChunkName: "component---src-pages-data-hub-forecast-and-alerts-email-sign-up-js" */),
  "component---src-pages-data-hub-forecast-and-alerts-js": () => import("./../../../src/pages/data-hub/forecast-and-alerts.js" /* webpackChunkName: "component---src-pages-data-hub-forecast-and-alerts-js" */),
  "component---src-pages-data-hub-forecast-and-alerts-recorded-call-sign-up-js": () => import("./../../../src/pages/data-hub/forecast-and-alerts/recorded-call-sign-up.js" /* webpackChunkName: "component---src-pages-data-hub-forecast-and-alerts-recorded-call-sign-up-js" */),
  "component---src-pages-data-hub-forecast-and-alerts-text-sign-up-js": () => import("./../../../src/pages/data-hub/forecast-and-alerts/text-sign-up.js" /* webpackChunkName: "component---src-pages-data-hub-forecast-and-alerts-text-sign-up-js" */),
  "component---src-pages-data-hub-forecast-and-alerts-unsubscribe-js": () => import("./../../../src/pages/data-hub/forecast-and-alerts/unsubscribe.js" /* webpackChunkName: "component---src-pages-data-hub-forecast-and-alerts-unsubscribe-js" */),
  "component---src-pages-data-hub-js": () => import("./../../../src/pages/data-hub.js" /* webpackChunkName: "component---src-pages-data-hub-js" */),
  "component---src-pages-data-hub-monitoring-reports-js": () => import("./../../../src/pages/data-hub/monitoring-reports.js" /* webpackChunkName: "component---src-pages-data-hub-monitoring-reports-js" */),
  "component---src-pages-data-hub-monitoring-stations-js": () => import("./../../../src/pages/data-hub/monitoring-stations.js" /* webpackChunkName: "component---src-pages-data-hub-monitoring-stations-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-scheme-checker-js": () => import("./../../../src/pages/scheme-checker.js" /* webpackChunkName: "component---src-pages-scheme-checker-js" */),
  "component---src-pages-schools-js": () => import("./../../../src/pages/schools.js" /* webpackChunkName: "component---src-pages-schools-js" */),
  "component---src-pages-schools-toolkit-js": () => import("./../../../src/pages/schools/toolkit.js" /* webpackChunkName: "component---src-pages-schools-toolkit-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-component-page-js": () => import("./../../../src/templates/component-page.js" /* webpackChunkName: "component---src-templates-component-page-js" */),
  "component---src-templates-disclaimer-js": () => import("./../../../src/templates/disclaimer.js" /* webpackChunkName: "component---src-templates-disclaimer-js" */),
  "component---src-templates-station-js": () => import("./../../../src/templates/station.js" /* webpackChunkName: "component---src-templates-station-js" */),
  "component---src-templates-tube-js": () => import("./../../../src/templates/tube.js" /* webpackChunkName: "component---src-templates-tube-js" */)
}

